import React, { useEffect, useState } from 'react';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonIcon, IonList, IonItem, IonLabel, IonCol, IonToast } from '@ionic/react';
import { heartCircle } from 'ionicons/icons';
import AppContext from "../some-content";
import axios from 'axios' ;
import { ownServer } from '../ownServer';

const Manage: React.FC<RouteComponentProps> = ({history}) => {

  const { sharedValue } = React.useContext(AppContext);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }

  var userId = sharedValue.value;
  var chooseNode;

  console.log("UserId: " + userId);

  function convertUTCDateToLocalDate(dateInput: string) {
    var date = new Date(dateInput?.replace(/\s+/g, 'T'));
    var timeOffset = new Date().getTimezoneOffset();
    var currentTime = new Date (date.getTime() - (timeOffset*60*1000));
    return currentTime;
  }
  
  if(history.location.state != undefined){
    const eventList = history.location.state as Array<Array<String>>;  
    let eventTitleList:String[] = [];

    if(eventList[0] != undefined){
      for (var i = 0; i < eventList[0].length; i ++){
        eventTitleList.unshift(eventList[0][i]);
      }

      chooseNode = eventTitleList.map(function(content:any){
        let eventView;
        let path='';

        // content [0] is event id; content [1] is event keyword; content [2] is record time
        if(content[1] == "Unknown"){
          content[1] = "Some emotions...";
        }

        function clickSingleEvent(){
          let url = ownServer.prodIp + "/manageevent";
          let dataPost = {userid: userId, eventid:content[0]};
          axios.post(url, dataPost)
          .then(function (response) {
            let data = response.data;
            if(data == "error" || data == "fail"){
              console.log("error: can't access event detail in /manageevent")
            }
            else{
              if(content[1] == "Some emotions..."){
                eventView="./emotiononly"
                path='/emotiononly'
              }
              else{
                eventView="./event"
                path='/event'
              }
              history.push({
                pathname:path,
                state:data.Event
              });
            };
          })
          .catch(function (error) {
            console.log(error);
          });
        }

        var recordTime = content[2] as string;
        var recordTimeLocal = convertUTCDateToLocalDate(recordTime).toLocaleString();

        if(content[2] != undefined){
          if (recordTime.length < 15){
            return (
              <IonItem className="manageList" button routerLink={eventView} onClick={clickSingleEvent}>
                <IonIcon icon={heartCircle} color="white"></IonIcon>
                <IonCol size='0.5'></IonCol>
                <IonLabel>
                  <div>{}</div>
                  <div>{}</div>
                </IonLabel>
              </IonItem>
            )
          }
          else{
            return (
              <IonItem className="manageList" button routerLink={eventView} onClick={clickSingleEvent}>
                <IonIcon icon={heartCircle} color="white"></IonIcon>
                <IonCol size='0.5'></IonCol>
                <IonLabel>
                  <strong>{content[1]}</strong>
                  <div>{recordTimeLocal}</div>
                </IonLabel>
              </IonItem>
            )
          }
        }
      });
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Manage</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="manageList">
        {/* <Prompt
          message='Are you sure you want to reload this page? If you do you will be logged out.'
        /> */}
        <IonList>
          {chooseNode}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Manage;
