import React, { useEffect, useState } from "react";
import { RouteComponentProps } from 'react-router-dom';
import axios from 'axios' ;
import { Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import { IonContent,IonButtons, IonBackButton, IonToast, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import AppContext from "../some-content";
import "react-big-calendar/lib/css/react-big-calendar.css";
import './frontend.css';
import { ownServer } from "../ownServer";

const Cal: React.FC<RouteComponentProps> = ({history}) => {

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const [showToast1, setShowToast1] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const { sharedValue } = React.useContext(AppContext);
  const localizer = momentLocalizer(moment);

  var userId = sharedValue.value;

  console.log(userId);

  // initialise the event list
  const eventsContainer = {
    events: [
      {
        start: moment().toDate(),
        end: moment()
          //.add(1, "hours")
          .toDate(),
        title: "Empty event",
        id:"000000",
        // allDay: true,
      },
    ]
  };

  function convertUTCDateToLocalDate(dateInput: string) {
    var date = new Date(dateInput?.replace(/\s+/g, 'T'));
    var timeOffset = new Date().getTimezoneOffset();
    var currentTime = new Date (date.getTime() - (timeOffset*60*1000));
    return currentTime;
  }

  // if there is/are event(s) recorded by this user, read the event list
  if(history.location.state != undefined){
    const event = history.location.state as Array<Array<String>>;

    // if there is at least one event recorded
    if(event[0] != undefined){
      for (var i = 0; i < event[0].length; i ++){
        var dateString = event[0][i][2];
        var dateLocal = convertUTCDateToLocalDate(dateString);
        var eventId = event[0][i][0];
        var keyword;
        if (event[0][i][1] == "Unknown"){
          keyword = "Some emotions..."; 
        }
        else{
          keyword = event[0][i][1]; 
        }
        let addEvent = {
          start: dateLocal,
          end: dateLocal,
          title: keyword,
          id:eventId,
        }
        try {
          if (eventsContainer.events[0].id == "000000") eventsContainer.events[0] = addEvent;
          else eventsContainer.events.push(addEvent);
        }
        catch (e)
        {
          setShowToast2(true);
        }
      }
    }
  }

  function clickEvent(event:any)
  {
    let path;
    let id = event.id;
    let keyword = event.title;

    let url = ownServer.prodIp + "/manageevent";
      
    let dataPost = {userid: userId, eventid:id};
    axios.post(url,dataPost)
    .then(function (response) {
      let data = response.data;
      if(data == "error" || data == "fail"){
        console.log("error")
      }
      else{
        if(keyword == "Some emotions..."){
          path='/emotiononly'
        }else{
          path='/event'
        }
        history.push({
          pathname:path,
          state:data.Event
        });
      };
    })
    .catch(function (error) {
      console.log(error);
    });

    if (id == "000000"){
      setShowToast1(true);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Calendar</IonTitle>
          <IonButtons slot="start">
           <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image-theme">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Calendar</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRow className="ion-justify-content-center">
          <Calendar
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            events={eventsContainer.events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            views={['month', 'day']}
            onSelectEvent={event => clickEvent(event)}
            className="calendar-style"
          />
        </IonRow>
        <IonToast 
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Empty Record - Try to record an event today"
          duration={5000}
        />
        <IonToast 
          isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          message="Error: failed to push the event data"
          duration={5000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Cal;
