import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton,IonButtons, IonIcon, IonItem, IonGrid, IonRow, IonCol, IonAvatar } from '@ionic/react';
import { earth, informationCircleOutline, chevronBackOutline } from 'ionicons/icons';
import './infoDemograph'
import './infoPersonal'
import React, { useEffect } from 'react';
import AppContext from "../some-content";
import axios from 'axios' ;
import pic from '../img/brain.jpg';
import './frontend.css';
import { ownServer } from '../ownServer';

const EditInfo: React.FC<RouteComponentProps> = ({history}) => {

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  
  const { sharedValue } = React.useContext(AppContext);
  const info = history.location.state as Array<string>;

  var userId = sharedValue.value;
  var email;
  var routerFinalLink;

  if(info != undefined) {
    email = info[0]
  }
  
  function handleDemoClick(){
    let url = ownServer.prodIp + "/email";
  
    let dataPost = {userid: userId,};
    axios.post(url,dataPost)
    .then(function (response) {
      let data = response.data;
      if(data == "error" || data == "fail"){
        console.log("error")
      }
      else{
        console.log(data)
        routerFinalLink="./infoDemograph";
        history.push({
          pathname:'/infoDemograph',
          state:data.Demo
        });
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  let routerFinalLink_P;

  function handlePersonClick(){
    let url = ownServer.prodIp + "/email";
  
    let dataPost = {userid: userId,};
    axios.post(url,dataPost)
    .then(function (response) {
      let data = response.data;
      if(data == "error" || data == "fail"){
        console.log("error")
      }
      else{
        console.log(data)
        routerFinalLink_P="./infoPersonal";
        history.push({
          pathname:'/infoPersonal',
          state:data.Demo
        });
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {/* <IonBackButton /> */}
            <IonButton fill="clear" size="large" routerLink="/tab3" onClick={(e) => {
              e.preventDefault();
              history.push('/tab3')}}><IonIcon icon={chevronBackOutline}></IonIcon>Back
            </IonButton>
          </IonButtons>
          <IonTitle>Edit Info</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image-theme">
        <IonGrid>
          <IonRow><h1>&nbsp;</h1></IonRow>
          <IonRow><h1>&nbsp;</h1></IonRow>
          <IonRow className="ion-justify-content-center">
            <IonAvatar> <img src = {pic}></img> </IonAvatar>
          </IonRow>
          <IonRow><h5>&nbsp;</h5></IonRow>
          <IonRow className="ion-justify-content-center">
            <h5 className='white'>{email}</h5>
          </IonRow>
          <IonRow><h5>&nbsp;</h5></IonRow>

          <IonRow className="ion-justify-content-center">
            <IonItem lines="none">
              <IonButton className="button-length" routerLink={routerFinalLink} color="textthree" size="default" fill ="solid" onClick={handleDemoClick}>&nbsp; Demographic Info &nbsp;
                <IonIcon icon={earth} />
              </IonButton>
            </IonItem>
          </IonRow>

          <IonRow className="ion-justify-content-center">
            <IonItem lines="none">
              <IonButton className="button-length" routerLink={routerFinalLink_P} color="textfive" size="default" fill ="solid" onClick={handlePersonClick}>&nbsp; Personal &nbsp; Info &nbsp; &nbsp; &nbsp;&nbsp;
                <IonIcon icon={informationCircleOutline} />
              </IonButton>
            </IonItem>
          </IonRow>
          <IonRow><h1></h1></IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default EditInfo;