import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonPage, IonButton, IonIcon, IonGrid, IonRow} from '@ionic/react';
import { clipboard, fitness, earth, helpCircle } from 'ionicons/icons';
import './frontend.css';
import axios from 'axios' ;
import AppContext from "../some-content";
import { ownServer } from '../ownServer';

const Tab1: React.FC<RouteComponentProps> = ({history}) => {

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  const userId = sharedValue.value;
  let routerFinalLink;

  console.log(userId);

  function handleManageClick(){
    let url = ownServer.prodIp + "/manage";
    let dataPost = {userid: userId,};
    axios.post(url,dataPost)
    .then(function (response) {
      let eventList = response.data;
      if(eventList == "error" || eventList == "fail"){
        console.log("error")
      }
      else{
        console.log(eventList)
        routerFinalLink="./manage";
        history.push({
          pathname:'/manage',
          state:eventList.Event
        });
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return (
    <IonPage>
      <IonContent class="background-image">
        <div>
          <h1>&nbsp;</h1>
          <h1>&nbsp;</h1>
          <h1>&nbsp;</h1>
        </div>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonButton className="button-length" color="text" size="large" routerLink="/record" onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname:'/record',
                state:userId
              })}}>
              RECORD &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <IonIcon icon={clipboard} />
            </IonButton>
          </IonRow>
          
          <IonRow className="ion-justify-content-center">
            <IonButton className="button-length" color="texttwo" size="large" routerLink={routerFinalLink} onClick={handleManageClick}>
              CONVERSATIONS&nbsp;
              <IonIcon icon={fitness} />
            </IonButton>
          </IonRow>
          
          <IonRow className="ion-justify-content-center">
            <IonButton className="button-length" color="textthree" size="large" routerLink="/support" onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname:'/support',
                state:userId
              })}}>
              SUPPORT &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <IonIcon icon={earth} />
            </IonButton>
          </IonRow>
          
          <IonRow className="ion-justify-content-center">
            <IonButton className="button-length" color="textfive" size="large" onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname:'/knowledge',
                state:userId
              })}}>
              PTSD &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <IonIcon icon={helpCircle} />
            </IonButton>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
