import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons,IonBackButton, IonGrid, IonRow, IonCol } from '@ionic/react';

const Knowledge: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>What is PTSD?</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="ion-text-start">
                <h5>What is PTSD</h5>
                <p>Post-traumatic stress disorder (PTSD) is a group of stress reactions that can develop after we witness a traumatic event or events, such as death, serious injury, natural disaster or any form of violence including sexual violence to ourselves or to others. PTSD can result from one event or a number of events over a period of time. </p>
                <h5>Coping after a traumatic event</h5>
                <p>There are things you can do that may help you cope with a traumatic event including:</p>
                <li>seeking social support</li>
                <li>talking to family and friends, or someone else who is good at listening and offering support</li>
                <li>talking to someone in your community (church members, colleagues, mental health professionals)</li>
                <li>talking about it early (getting timely support might be a factor in preventing your normal stress reactions from developing into PTSD)</li>
                <li>staying away from drugs and alcohol as a way of coping</li>
              </div>
            </IonCol>
          </IonRow> 
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Knowledge;
