import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonChip, IonButton,IonButtons, IonGrid, IonRow } from '@ionic/react';
import './frontend.css'
import AppContext from "../some-content";
import axios from 'axios' ;
import { ownServer } from '../ownServer';

const Record04: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  
  var userId = sharedValue;
  var location;
  var event = history.location.state;

  console.log(userId);
  console.log(event);

  function handleSaveClick(locationPara:any){
    let url = ownServer.prodIp + "/recordlocation";
    let dataPost = {
      userid: userId,
      eventid:event,
      locationInput:locationPara,
    };
    axios.post(url,dataPost)
    .then(function (response) {          
      let data = response.data;
      if(data == "error" || data == "fail"){
      }
        history.push({
          pathname:'/record05',
          state:event,
        });
      })
    .catch(function (error) {
      console.log(error);
    });
  }

  function handleHomeClick(){
    location = "at home";
    handleSaveClick(location);
  }

  function handleWorkClick(){
    location = "at work"
    handleSaveClick(location);
  }

  function handleSchoolClick(){
    location = "at school";
    handleSaveClick(location);
  }
  
  function handlePublicClick(){
    location = "other public places";
    handleSaveClick(location);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {/* <IonBackButton /> */}
          </IonButtons>
          <IonTitle>Record 2/6</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image">
        <IonGrid>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonChip className="bigTitle1">
              <h5 className="blockText-big">Where did it happen?</h5>
            </IonChip>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton color="text" routerLink="/record05" onClick={handleHomeClick}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;At&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Home&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonButton>
          </IonRow>
          <IonRow><h5></h5></IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton color="texttwo" routerLink="/record05" onClick={handleWorkClick}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;At&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Work&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonButton>
          </IonRow>
          <IonRow><h5></h5></IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton color="textthree" routerLink="/record05" onClick={handleSchoolClick}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;At&nbsp;&nbsp;&nbsp;&nbsp;School&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonButton>
          </IonRow>
          <IonRow><h5></h5></IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton color="textfive" routerLink="/record05" onClick={handlePublicClick}> Other Public Places
            </IonButton>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Record04;
