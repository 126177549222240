import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton,IonButtons,IonBackButton, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import { arrowForwardCircleOutline } from 'ionicons/icons';
import AppContext from "../some-content";

const Emotiononly: React.FC<RouteComponentProps> = ({history}) => {

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  
  const { sharedValue } = React.useContext(AppContext);
  
  var userId = sharedValue.value;
  var chooseNode;

  console.log(userId);

  if(history.location.state != undefined){
    const event = history.location.state as Array<Array<String>>;

    var chooseNode;
    let titles:String[] = [];

    if(event[1] != undefined){
      for (var i = 1; i < event[1].length; i ++){
        titles.unshift(event[1][i]);
        console.log(event[1][i]);      
      }
      
      chooseNode = titles.map(function(content:String){
          return (
            <IonButton size="small" color="healing">
              <span>{content}</span>  
            </IonButton>
          )
      });
    }
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton fill="clear" color="black" routerLink="/tab1" onClick={(e) => {
              e.preventDefault();
              history.push('/tab1')}}>Got it
            </IonButton>
          </IonButtons>
          <IonTitle>Emotions Record</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
        <IonRow><h4>&nbsp; &nbsp;I felt:</h4></IonRow>
        <IonRow>
          <IonCol>{chooseNode}</IonCol>
        </IonRow>
        </IonGrid> 
        <IonGrid>
          <IonRow><h1></h1></IonRow>
          <IonRow className="ion-justify-content-center"> 
            <IonButton color='texttwo' href="/support" onClick={(e) => {
              e.preventDefault();history.push('/support')
              }}> You are not alone&nbsp;&nbsp;
              <IonIcon icon={arrowForwardCircleOutline} />
            </IonButton>
          </IonRow>
          <h1>&nbsp;</h1>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Emotiononly;
