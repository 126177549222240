import React, { useEffect, useState }  from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonCheckbox,IonToast, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonButton, IonItem } from '@ionic/react';
import AppContext from "../some-content";
import axios from 'axios' ;
import './frontend.css';
import { ownServer } from '../ownServer';

const Tab3: React.FC<RouteComponentProps> = ({history}) => {

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const [showToast1, setShowToast1] = useState(false);
  const { sharedValue } = React.useContext(AppContext);
  const [checked, setChecked] = useState(false);
  var userId = sharedValue.value;
  let routerFinalLink;

  function handleResearch(){ 
    let url = ownServer.prodIp + "/research";
    let dataPost = {userid: userId, agreeR:checked};
    axios.post(url,dataPost)
    .then(function (response) {
      let data = response.data;
      if(data == "error" || data == "fail"){
        console.log("error")
      }
      else{
        setShowToast1(true)
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  function handleSaveClick(){
    let url = ownServer.prodIp + "/email";
    let dataPost = {userid: userId,};
    axios.post(url,dataPost)
    .then(function (response) {
      let data = response.data;
      if(data == "error" || data == "fail"){
        console.log("error")
      }
      else{
        console.log(data)
        routerFinalLink="./EditInfo";
        history.push({
          pathname:'/EditInfo',
          state:data.Demo
        });
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Hello!</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        
        {/* <div>
          {sharedValue.value} : {sharedValue.changedBy}{" "}
        </div> */}
        
        <IonGrid>
          <IonRow><IonCol>
            <h3 className="blockText2-big">We are glad to hear from you!</h3></IonCol></IonRow>
          <IonRow className="ion-justify-content-center">
            {/* <IonAvatar> <img src = {pic}></img> </IonAvatar> */}
          </IonRow>
          <IonRow><IonCol><p>Would you like to share your information and data for the purpose of research? If so, please tick the checkbox below, and edit your personal information. We guarantee the user the data is only for statistical and research purposes to assist understand Mental health and it will not be used for any other purpose without your consent.</p></IonCol></IonRow>
          <IonRow className="ion-justify-content-center">
            <IonItem lines="none"><IonCheckbox checked={checked} slot="end" onIonChange={(e) => {setChecked(e.detail.checked);handleResearch()}} />I would like to share my information and data for PTSD research</IonItem>
          </IonRow>
          <IonRow>
            <h3>&nbsp;</h3>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton size="default" color="heal" routerLink={routerFinalLink} onClick={handleSaveClick}>&nbsp;&nbsp; Edit&nbsp; My&nbsp; Info&nbsp;&nbsp;</IonButton>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton routerLink="/login"  size="default" color="textfour" onClick={(e) => {
              e.preventDefault();
              history.push('/login')}}>&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;Log&nbsp; &nbsp;out&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            </IonButton>
          </IonRow>
        </IonGrid>
        <h1></h1>
        <IonToast 
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Your response is received, thank you!"
          duration={1000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Tab3;