import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader,IonRouterLink, IonPage, IonTitle, IonToolbar, IonButtons,IonBackButton, IonIcon, IonList, IonItem, IonGrid, IonCol } from '@ionic/react';
import { bulbOutline } from 'ionicons/icons';

const Knowledge: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>PTSD Knowledge</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonList>

            <IonItem>
              <IonIcon icon={bulbOutline}></IonIcon>
              <IonCol size='0.5'></IonCol>
              <IonRouterLink routerLink="/Knowledge01" color="texttwo" onClick={(e) => {
                e.preventDefault();
                history.push('/Knowledge01')}}>What is PTSD?
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <IonIcon icon={bulbOutline}></IonIcon>
              <IonCol size='0.5'></IonCol>
              <IonRouterLink routerLink="/Knowledge02" color="texttwo" onClick={(e) => {
                e.preventDefault();
                history.push('/Knowledge02')}}>Signs and symptoms of PTSD
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <IonIcon icon={bulbOutline}></IonIcon>
              <IonCol size='0.5'></IonCol>
              <IonRouterLink routerLink="/Knowledge03" color="texttwo" onClick={(e) => {
                e.preventDefault();
                history.push('/Knowledge03')}}>Getting treatment for PTSD
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <IonIcon icon={bulbOutline}></IonIcon>
              <IonCol size='0.5'></IonCol>
              <IonRouterLink routerLink="/Knowledge04" color="texttwo" onClick={(e) => {
                e.preventDefault();
                history.push('/Knowledge04')}}>Psychological treatments for PTSD
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <IonIcon icon={bulbOutline}></IonIcon>
              <IonCol size='0.5'></IonCol>
              <IonRouterLink routerLink="/Knowledge05" color="texttwo" onClick={(e) => {
                e.preventDefault();
                history.push('/Knowledge05')}}>Grounding Techniques
              </IonRouterLink>
            </IonItem>

          </IonList>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Knowledge;
