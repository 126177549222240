import { RouteComponentProps } from 'react-router-dom';
import { IonToast, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonChip, IonButton,IonButtons, IonGrid, IonRow, IonCol, IonInput } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import AppContext from "../some-content";
import axios from 'axios' ;
import { ownServer } from '../ownServer';

const Record05: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  const [keyword, setKeywordValue] = React.useState<string>()
  const [showToast1, setShowToast1] = useState(false);

  var userId = sharedValue.value;
  var event = history.location.state;
  let routerFinalLink;

  console.log(userId);
  console.log(event);

  function handleSaveClick(){
    let url = ownServer.prodIp + "/recordkeyword";
    if(keyword != "" && keyword != null){
      let dataPost = {keyword:keyword,userid: userId,eventid:event,};
      axios.post(url,dataPost)
      .then(function (response) {
        let data = response.data;
        if(data == "error" || data == "fail")
        {
        }
        else{
          routerFinalLink="./record06";
          history.push({
            pathname:'/record06',
            state:event
          });
        };
        console.log(data);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    else{
      setShowToast1(true)
    }
    setKeywordValue("");
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/* <IonButtons slot="start">
            <IonBackButton />
          </IonButtons> */}
          <IonButtons slot="end">
            <IonButton fill="clear" color="black" routerLink={routerFinalLink} onClick={handleSaveClick}>Next
            </IonButton>
          </IonButtons>
          <IonTitle>Record 3/6</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image-theme">
        <IonGrid>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol>
              <h4 className="white">What is the first thing that comes to your mind?</h4>
            </IonCol>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <p className="white">This is a safe place to talk your truths</p>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          
          <IonRow className="ion-justify-content-center">
            <IonChip>
              <IonInput maxlength={999} placeholder="Write a word or phrase" type="text" color="bluethree" value={keyword} onIonChange={e => setKeywordValue(e.detail.value!)}></IonInput>
            </IonChip>
          </IonRow>   
        </IonGrid>
        <IonToast 
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Cannot be empty"
          duration={800}
        />
      </IonContent>
    </IonPage>
  );
};

export default Record05;
