import { RouteComponentProps } from 'react-router-dom';
import { IonToast,IonCard, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonChip, IonImg, IonButton,IonButtons, IonGrid, IonRow, IonCol, IonInput } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import AppContext from "../some-content";
import axios from 'axios' ;
import pic from '../img/positive.jpg';
import { ownServer } from '../ownServer';

const Record08: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  const [positive, setPositiveValue] = React.useState<string>();
  const [positive_2, setPositiveValue_2] = React.useState<string>();
  const [positive_3, setPositiveValue_3] = React.useState<string>();
  const [showToast1, setShowToast1] = useState(false);

  var userId = sharedValue.value;
  var event = history.location.state;
  let routerFinalLink;

  console.log(userId);
  console.log(event);

  function handleSaveClick(){
    let url = ownServer.prodIp + "/recordpositive";

    if(positive != "" && positive != null && positive_2 != "" && positive_2 != null && positive_3 != "" && positive_3 != null){
      let dataPost = {positive:positive, positive_2:positive_2, positive_3:positive_3, userid: userId, eventid:event};
      axios.post(url,dataPost)
      .then(function (response) {
        let data = response.data;
        if(data == "error" || data == "fail"){
          console.log("error")
        }
        else{
          routerFinalLink="./feedback";
          history.push({
            pathname:'/feedback',
            state:data.Emotion
          });
        };
        console.log(data.Emotion);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    else{
      setShowToast1(true)
    }
    setPositiveValue("");
    setPositiveValue_2("");
    setPositiveValue_3("");
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/* <IonButtons slot="start">
            <IonBackButton />
          </IonButtons> */}
          <IonButtons slot="end">
            <IonButton fill="clear" color="black" routerLink={routerFinalLink} onClick={handleSaveClick}>Submit
            </IonButton>
          </IonButtons>
          <IonTitle>Record 6/6</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Record 6/6</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard>
          <IonImg src = {pic}></IonImg>
        </IonCard>

        <IonGrid>
          <IonRow>
            <IonCol>
                <h4>Find something positive in the last day or two</h4>
            </IonCol>
          </IonRow>
          
          <IonRow className="ion-justify-content-center">
            <IonChip>
              <IonInput maxlength={999} placeholder="1. Something positive" type="text" value={positive} onIonChange={e => setPositiveValue(e.detail.value!)}></IonInput>
            </IonChip>
          </IonRow>

          <IonRow className="ion-justify-content-center">
            <IonChip className="positive2">
              <IonInput maxlength={999} placeholder="2. Something positive" type="text" value={positive_2} onIonChange={e => setPositiveValue_2(e.detail.value!)}></IonInput>
            </IonChip>
          </IonRow>

          <IonRow className="ion-justify-content-center">
            <IonChip className="positive3">
              <IonInput maxlength={999} placeholder="3. Something positive" type="text" value={positive_3} onIonChange={e => setPositiveValue_3(e.detail.value!)}></IonInput>
            </IonChip>
          </IonRow>
        </IonGrid>
        <IonToast 
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Cannot be empty"
          duration={800}
        />
      </IonContent>
    </IonPage>
  );
};

export default Record08;
