import { RouteComponentProps } from 'react-router-dom';
import { IonToast,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonInput, IonButton, IonLabel, IonGrid, IonRow, IonImg, IonRouterLink, IonAvatar} from '@ionic/react';
import React, { useState } from 'react';
import axios from 'axios' ;
import AppContext from "../some-content";
import './frontend.css';
import pic from '../img/brain.jpg';
import { ownServer } from '../ownServer';

const Login: React.FC<RouteComponentProps> = ({history}) => {

  const [emailInputL, setEmailValueL] = React.useState<string>()
  const [passwordInputL, setPasswordValueL] = React.useState<string>()
  const [showToast1, setShowToast1] = useState(false);
  const [showToastEmail, setShowEmail] = useState(false);
  const [showToastPWLength, setShowLength] = useState(false);
  const [showToastWrong, setShowInfoWrong] = useState(false);
  const [showNotUser, setShowNotUser] = useState(false);
  const { setSharedValue } = React.useContext(AppContext);
  
  let routerFinalLink;
  let max_password = 20;

  function handleLoginClick(){
    let url = ownServer.prodIp + "/login";
    console.log(url + " Version: 04102022-1");
    if(emailInputL != null && passwordInputL != null){
      if(emailInputL.indexOf("@") == -1){
        setShowEmail(true)
      }else if(passwordInputL.length < 6){
        setShowLength(true)
      }else{
        let dataPost = {email:emailInputL,password:passwordInputL};
        axios.post(url,dataPost)
        .then(function (response) {
          let userId = response.data;
          if(userId == "error" || userId == "fail"){
            setShowInfoWrong(true)   
          }else if(userId == "notuser"){
            setShowNotUser(true)
          }
          else{
            routerFinalLink="./tab1";
            history.push({
              pathname:'/tab1',
              state:userId
            });
            setSharedValue({ value: userId, changedBy: "Login" })
            setEmailValueL("");
            setPasswordValueL("");
          };
          console.log(userId);
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }else{
      setShowToast1(true)
    }
  }

  function openBrowser(){
    window.open("https://www.thementalmentorwhelenrose.com",'_system', 'location=yes');
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>User Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image-theme">
        {/* <IonCard>
          <IonImg src = {pic}></IonImg>
        </IonCard> */}
        <div>
          <h3 className="blockText-white"> Start </h3>
          <h3 className="blockText-white"> the </h3>
          <h3 className="blockText-white"> conversation </h3>
        </div>
        <IonItem className="background-blue" lines="inset">
          <IonLabel color="white">
            <b>Username</b>
          </IonLabel>
          <IonInput placeholder="Email" color="white" type="text" maxlength={100} value={emailInputL} onIonChange={e => setEmailValueL(e.detail.value!)}></IonInput>
        </IonItem>
        <IonItem className="background-blue" lines="inset">
          <IonLabel color="white">
            <b>Password</b>
          </IonLabel>
          <IonInput placeholder="Password" color="white" type="password" value={passwordInputL} maxlength={max_password} onIonChange={e => setPasswordValueL(e.detail.value!)}></IonInput>
        </IonItem>

        <IonGrid>
          <h1></h1>
          <IonRow className="ion-justify-content-center">
            <IonButton color="texttwo" routerLink={routerFinalLink} onClick={handleLoginClick}>Log In</IonButton>
          </IonRow>
          <h1>&nbsp;</h1>
          <IonRow className="ion-justify-content-center">
            <IonRouterLink color="white" href="/register" onClick={(e) => {
              e.preventDefault();history.push('/register')
            }}> <b>Join us now! Register here</b></IonRouterLink>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton size='large' fill='clear' color='white'>
              The Mental Mentor
            </IonButton>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton size='small' fill='clear' color='white' onClick={openBrowser}>
              www.thementalmentorwhelenrose.com
            </IonButton>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonAvatar className="ion-justify-content-center">
              <IonImg src = {pic}></IonImg>
            </IonAvatar>
          </IonRow>
        </IonGrid>

        <IonToast 
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Email or Password cannot be empty"
          duration={800}
        />
        <IonToast 
          isOpen={showToastEmail}
          onDidDismiss={() => setShowEmail(false)}
          message="Sorry, not an Email."
          duration={800}
        />
        <IonToast 
          isOpen={showToastPWLength}
          onDidDismiss={() => setShowEmail(false)}
          message="Sorry, at least 6 digits for Password."
          duration={800}
        />
        <IonToast 
          isOpen={showToastWrong}
          onDidDismiss={() => setShowInfoWrong(false)}
          message="Email or Password is wrong, please retry."
          duration={800}
        />
        <IonToast 
          isOpen={showNotUser}
          onDidDismiss={() => setShowNotUser(false)}
          message="Not a user yet, join us now!"
          duration={800}
        />
      </IonContent>
    </IonPage>
  );
};


export default Login;
