import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonChip, IonButton,IonButtons, IonGrid, IonRow } from '@ionic/react';
import './frontend.css'
import AppContext from "../some-content";
import axios from 'axios' ;
import { ownServer } from '../ownServer';

const Record03: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  
  var userId = sharedValue;
  var event = history.location.state;
  var time;

  console.log(userId);
  console.log(event);

  function handleSaveClick(timePara:any){
    let url = ownServer.prodIp + "/recordtime";
    
    let dataPost = {
      userid: userId,
      eventid:event,
      timeInput:timePara,
    };
    axios.post(url,dataPost)
    .then(function (response) {          
      let data = response.data;
      if(data == "error" || data == "fail"){
      }
        history.push({
          pathname:'/record04',
          state:event,
        });
      })
    .catch(function (error) {
      console.log(error);
    });
  }

  function handleTodayClick(){
    time = "today";
    handleSaveClick(time);
  }

  function handleFewDaysClick(){
    time = "few days ago";
    handleSaveClick(time);
  }

  function handleAMonthClick(){
    time = "a month ago";
    handleSaveClick(time);
  }
  
  function handleYearsClick(){
    time = "years ago";
    handleSaveClick(time);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
             {/* <IonBackButton /> */}
          </IonButtons>
          <IonTitle>Record 1/6</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image">
        <IonGrid>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonChip className="bigTitle1">
              <h5 className="blockText-big">When did that happen?</h5>
            </IonChip>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton className="button-length" color="text" routerLink="/record04" onClick={handleTodayClick}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Today&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonButton>
          </IonRow>
          <IonRow><h5></h5></IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton className="button-length" color="texttwo" routerLink="/record04" onClick={handleFewDaysClick}>Few days ago</IonButton>
          </IonRow>
          <IonRow><h5></h5></IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton className="button-length" color="textthree" routerLink="/record04" onClick={handleAMonthClick}>&nbsp; A month ago
            </IonButton>
          </IonRow>
          <IonRow><h5></h5></IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton className="button-length" color="textfive" routerLink="/record04" onClick={handleYearsClick}> &nbsp;&nbsp;&nbsp;Years ago&nbsp;&nbsp;&nbsp;
            </IonButton>
          </IonRow> 
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Record03;
