import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons,IonBackButton, IonGrid, IonRow, IonCol } from '@ionic/react';
 

const Knowledge: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }

  return (
      <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Psychological treatments for PTSD</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h5>Psychological treatments for PTSD</h5>
              <p>Psychological treatments are sometimes called ‘talking therapies’. Talking therapies can help us change habits in the way we think, and help us cope better with life’s challenges.</p>
              <p>Psychological treatments can be one-on-one interactions, group-based or online. Psychological treatments can help us address the reasons behind our PTSD, and also prevent it from returning.</p>
              <p>The Black Dog Institute recommends the following psychological treatments for PTSD:</p>
              <li>trauma-focused cognitive behavioural therapy (CBT)</li>
              <li>eye movement desensitisation and reprocessing (EMDR)</li>
              <p>&nbsp;</p>
              <p>When cognitive behavioural therapy is used for PTSD treatment, there are two main parts:</p>
              <p> A cognitive component that challenges and modifies our distorted thoughts about the trauma, ourselves and the world around us, that have arisen because of a traumatic experience.
                  A behavioural component where we confront the memory of the traumatic event or situation in a safe environment (imaginal exposure); exposing us gradually and repeatedly to the triggers of our fear and stress, until we’re no longer distressed by them.
              </p>
              <p>EMDR (Eye Movement Desensitization and Reprocessing)</p>
              <p>is a psychotherapy that enables people to heal from the symptoms and emotional distress that are the result of disturbing life experiences.  Repeated studies show that by using EMDR therapy people can experience the benefits of psychotherapy that once took years to make a difference. It is widely assumed that severe emotional pain requires a long time to heal.  EMDR therapy shows that the mind can in fact heal from psychological trauma much as the body recovers from physical trauma.  When you cut your hand, your body works to close the wound.  If a foreign object or repeated injury irritates the wound, it festers and causes pain.  Once the block is removed, healing resumes.  EMDR therapy demonstrates that a similar sequence of events occurs with mental processes.  The brain’s information processing system naturally moves toward mental health.  If the system is blocked or imbalanced by the impact of a disturbing event, the emotional wound festers and can cause intense suffering.  Once the block is removed, healing resumes.  Using the detailed protocols and procedures learned in EMDR therapy training sessions, clinicians help clients activate their natural healing processes.</p>
              <p>&nbsp;</p>
              <p>References:</p>
              <p>https://www.emdr.com/what-is-emdr/</p>
              <p>https://www.blackdoginstitute.org.au/resources-support/post-traumatic-stress-disorder/treatment/</p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Knowledge;
