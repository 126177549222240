import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton,IonButtons, IonIcon, IonGrid, IonRow, IonText } from '@ionic/react';
import { arrowForwardCircleOutline } from 'ionicons/icons';
import AppContext from "../some-content";
import axios from 'axios' ;
import { ownServer } from '../ownServer';

const FeedbackPage: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);

  var userId = sharedValue.value;
  var chooseNode;
  var messageRandom:String[] = [];
  var eventId = "";
  var routerFinalLink;
  var str = "";

  console.log(userId);

  if(history.location.state != undefined){

    const event = history.location.state as Array<String>;
    console.log(event);

    eventId = String(event[0]);
    let titles:String[] = [];

    titles.unshift("Reading a book in the sunshine or enjoying dinner with a friend would be a better choice for you. ");
  
    for (var i = 0; i < event.length; i ++) {
      console.log(event[i]); 
      if(event[i]=="sleepless"){
        titles.unshift("Drinking a cup of hot milk or herbal tea before going to bed will help your sleep. ");
        titles.unshift("A hot bath or shower, watching an old favourite movie or reading something light in content. ");
      };
      if(event[i]=="withdrawn socially"){
        titles.unshift("What’s something you have always wanted to do but never done? Try something new. ");
        titles.unshift("Challenge yourself and step outside the box, new experiences and new people help you find your spirit. Join in activities with friends where you feel safe. ");
      };
      if(event[i]=="hyper activity"){
        titles.unshift("Learn it's okay to stop. It’s okay to face what’s happened – you are stronger than you think. Try in steps to have a lazy afternoon or morning. Start a hobby or craft that occupies your time but is calm and mindfulness such as yoga. As you heal this sense of having to keep going will reduce. ");
      };
      if(event[i]=="peaceful"){
        titles.unshift("Enjoy this moment. Note what you did to feel this way to assist when days are a little rough. ");
      };
      if(event[i]=="stressed" || event[i]=="axious"){
        titles.unshift("Listening to music, walking in open spaces, swimming, paint, running, or any physical activity. ");
        titles.unshift("Company of animals, pets, friends or family who understand your journey. ");
      };
      if(event[i]=="alcohol use"){
        titles.unshift("Be mindful and honest about alcohol intake – impacts your sleep and clarity of mind. ");
      };
      if(event[i]=="recurring vision"){
        titles.unshift("Note the frequency of these visions and its impact, these are traumas or incidents that you may need professional guidance to resolve. ");
        titles.unshift("A hot shower, herbal tea, hot milk and watching a movie may help shift the mindset if it wakes you. ");
      };
      if(event[i]=="feelings of inadequacy"){
        titles.unshift("Every single person is amazing, we are all worthy, and bring love and amazing things lives, no matter how hard we are struggling. ");
        titles.unshift("There is no manual to life, its okay to have a bad day, react poorly, lacking energy etc, just know you are important and amazing and make the choice to stay. ");
        titles.unshift("Find the positives, focus on what you have achieved and are achieving. ");
      };
      if(event[i]=="emotional" || event[i]=="feeling sad"){
        titles.unshift("Chat with your friends let them help you walk through this moment in time. ");
        titles.unshift("Listen to some cheerful music, do something you love, change your scenery, for example, go for a short road trip. ");
      };
      if(event[i]=="no energy"){
        titles.unshift("Maybe a gentle walk, gardening, enjoy an outing with a friend, read a book in the sunshine. ");
      };
      if(event[i]=="angry"){
        titles.unshift("Vent and verbalise to a trusted person to release what’s going on and created this feeling. You may not understand and can be unnerving. ");
        titles.unshift("Take time out - Exercise can assist to release the adrenaline from this emotion or an activity that has a calming impact. ");
      };
      if(event[i]=="numbness" || event[i]=="feel burden"){
        titles.unshift("Focus on positives in your life. Start writing down a positive from every day. ");
        titles.unshift("Be aware of the language you use, talking in the negative creates negative feelings, learn to talk in the positive, its about shifting the mindset. ");
      };
      if(event[i]=="drug use"){
        titles.unshift("Maybe it is a good idea to open mind and talk with the professional. ");
      };
      if(event[i]=="reacted poorly"){
        titles.unshift("Accept your behaviour is part of the trauma, it’s a reaction to a stressor. Note at the time or later what happened to help identify the triggers. ");
        titles.unshift("Take time for you and self-care. Please be kind to yourself. It’s okay to be human. ");
        titles.unshift("Talk it through with someone honestly. When you are ready to acknowledge your reaction and maybe apologise. ");
      };
      if(event[i]=="controlled"){
        titles.unshift("What’s something you have always wanted to do but never done? Try something new. ");
      };
      if(event[i]=="fail meet expectations"){
        titles.unshift("We are all human, you are enough. ");
        titles.unshift("Break your goals into smaller parts to reach the ultimate goal desired to help reduce the pressure on yourself.‘You’ve got this’. ");
      };
      if(event[i]=="physically harmed"){
        titles.unshift("If you have received a personal injury, whether a workplace injury or someone has hurt you, report this injury to the police, your supervisor or family and friends. ");
      };
      if(event[i]=="sweating breathing difficulties"){
        titles.unshift("Find a happy focus or an open space, something that calms you. Call a friend or ask someone to visit that provide reassurance and company. ");
      };
      if(event[i]=="can not concentrate"){
        titles.unshift("Healthy diet and exercise can help. Take a moment to re-focus or a break. ");
        titles.unshift("Try learning deep breathing into your stomach (put heavy books on your stomach and breathe till they raise up - it takes focus). ");
      };
      if(event[i]=="struggling decisions"){
        titles.unshift("Take your time, break the decision down into parts – make a list of positives and negative aspects, keep adding to it and let these sit with you. It’s about you, make the choice that you feel comfortable with and feel happy about. ");
      };
      if(event[i]=="feel lost" || event[i]=="abandoned"){
        titles.unshift("Reach out to friends or family or a place or activity that is familiar helps you feel connected and brings that sense of comfort. ");
      };
    }
  
    var max = titles.length;
    var indexRandom = Math.floor(Math.random()*(max-2));
    messageRandom = titles.slice(indexRandom,indexRandom+2)

    chooseNode = messageRandom.map(function(content:any){
      return (
        <li>{content}</li>
      )
    }.bind(this));
  }

  for (var i = 0; i < messageRandom.length; i ++){
    str = str + messageRandom[i] + "\n"; 
  }

  function handleSaveClick(){
    let url = ownServer.prodIp + "/tips";
    let dataPost = {userid: userId, eventid:eventId, tip:str};
    axios.post(url,dataPost)
    .then(function (response) {
      let data = response.data;
      if(data == "error" || data == "fail"){
        console.log("error")
      }
      else{
        routerFinalLink="./tab1";
        history.push({
          pathname:'/tab1',
        });
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  function handleSupportClick(){
    let url = ownServer.prodIp + "/tips";
    let dataPost = {userid: userId,eventid:eventId,tip:str};
    axios.post(url,dataPost)
    .then(function (response) {
      let data = response.data;
      if(data == "error" || data == "fail"){
        console.log("error")
      }
      else{
        routerFinalLink="./support";
        history.push({
          pathname:'/support',
        });
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/* <IonButtons slot="start">
            <IonBackButton />
          </IonButtons> */}
          <IonButtons slot="end">
            <IonButton fill="clear" color="black" routerLink={routerFinalLink} onClick={handleSaveClick}>Got it
            </IonButton>
          </IonButtons>
          <IonTitle>Well done</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image-theme">
        <IonGrid>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <h5 className="white">YOU ARE IMPORTANT...</h5>
            <h5 className="white">We are all amazing </h5>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonText color='white'>
              {chooseNode}
            </IonText>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton color='texttwo' href={routerFinalLink} onClick={handleSupportClick}>You are not alone&nbsp;&nbsp;
              <IonIcon icon={arrowForwardCircleOutline} />
            </IonButton>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default FeedbackPage;
