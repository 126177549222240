import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import 'bootstrap/dist/css/bootstrap.css';  // might not be used
import 'jquery/dist/jquery.js';             // might not be used
import 'popper.js/dist/umd/popper.js';      // might not be used
import 'bootstrap/dist/js/bootstrap.js';    // might not be used

// Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDF1gCKwhgylR7iBTh_AFNDvMM82RE3YJI",
  authDomain: "starlit-glider-337409.firebaseapp.com",
  projectId: "starlit-glider-337409",
  storageBucket: "starlit-glider-337409.appspot.com",
  messagingSenderId: "456248122499",
  appId: "1:456248122499:web:9521958b90a56a4fc30fb6",
  measurementId: "G-TY3K75QR2Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
