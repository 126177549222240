import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons,IonBackButton, IonGrid, IonRow, IonCol, } from '@ionic/react';

const Knowledge: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  
  return (
   <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Signs and symptoms of PTSD</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
                <h5>Signs and symptoms of PTSD</h5>
                <p>PTSD has four groups of symptoms:</p>
                <li>1.	Re-experiencing the trauma</li>
                <li>2.	Avoiding reminders of the event</li>
                <li>3.	Negative changes in thoughts and mood after the trauma</li>
                <li>4.	Feeling ‘on edge’ and overly aroused</li>
                <p>&nbsp;</p>
                <p>Re-experiencing the trauma symptoms may include:</p>
                <li>distressing and ‘intrusive’ thoughts and memories</li>
                <li>nightmares</li>
                <li>flashbacks of the trauma</li>
                <li>severe reactions to things that remind us of the traumatic event</li>
                <li>reliving the event which then leads to a racing heartbeat, difficulty breathing.</li>
                <p>&nbsp;</p>
                <p>Avoiding reminders of the event may include:</p>
                <li>people</li>
                <li>places</li>
                <li>objects</li>
                <li>conversations</li>
                <li>activities</li>
                <p>&nbsp;</p>
                <p>Negative changes in thoughts and mood after the trauma may include:</p>
                <li>not being able to remember part of the traumatic event</li>
                <li>heightened sense of danger</li>
                <li>blaming yourself or others for the event or its aftermath</li>
                <li>feeling very down or numb</li>
                <li>feeling strong guilt, horror or anger</li>
                <li>being unable to enjoy things you used to find pleasurable</li>
                <li>feeling detached or ‘cut off’ from people</li>
                <p>&nbsp;</p>
                <p>Feeling ‘on edge’ and overly aroused may include:</p>
                <li>irritability</li>
                <li>violent /angry outbursts</li>
                <li>reckless behaviour</li>
                <li>getting startled easily, feeling ‘jumpy’</li>
                <li>overly alert to danger (hypervigilance)</li>
                <li>finding it hard to sleep</li>
                <li>finding it hard to concentrate</li>
                <p>&nbsp;</p>
                <p>Reference: BlackdogInstitute.org.au</p>
             </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Knowledge;
