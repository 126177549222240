import React from "react";

// create the context
export const Context = React.createContext();

// create the context provider, we are using use state to ensure that
// we get reactive values from the context...
export const TheProvider = ({ children }) => {
  // the reactive values
  const [sharedValue, setSharedValue] = React.useState({
    value: "initial",
    changedBy: "Admin"
  });

  // the store object
  let state = {
    sharedValue,
    setSharedValue
  };

  // wrap the application in the provider with the initialized context
  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default Context;
