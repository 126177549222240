import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton,IonButtons, IonList, IonItem, IonLabel, IonGrid, IonCol, IonAvatar, IonIcon } from '@ionic/react';
import { callOutline, earth } from 'ionicons/icons';
// import { InAppBrowser, InAppBrowserOptions } from "@ionic-native/in-app-browser";
// import { Plugins } from '@capacitor/core';
// import { Browser } from '@capacitor/browser';


const Support: React.FC<RouteComponentProps> = ({history}) => {

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }

  function openBrowser(link : string){
    window.open(link, '_system', 'location=yes');
  }

  function openBrowser12(){
    window.open("https://www.beyondblue.org.au/get-support/national-help-lines-and-websites",'_system', 'location=yes');
  }

  function openBrowser1(){
    // const browser = InAppBrowser.create("https://www.health.nsw.gov.au/mentalhealth/services/Pages/support-contact-list.aspx");
    // browser.show();
    window.open("https://www.health.nsw.gov.au/mentalhealth/services/Pages/support-contact-list.aspx",'_system', 'location=yes');
  }

  function openBrowser2(){
    window.open("https://www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/services/mental+health+and+drug+and+alcohol+services/mental+health+services/mental+health+services",'_system', 'location=yes');   
  }

  function openBrowser3(){
    window.open("https://www.qld.gov.au/health/mental-health",'_system', 'location=yes');
  }

  function openBrowser4(){
    window.open("https://nt.gov.au/wellbeing/mental-health",'_system', 'location=yes');
  }

  function openBrowser5(){
    window.open("https://www.mhc.wa.gov.au/getting-help/",'_system', 'location=yes');
  }

  function openBrowser6(){
    window.open("https://www.health.act.gov.au/services-and-programs/mental-health/getting-help",'_system', 'location=yes');
  }

  function openBrowser7(){
    window.open("https://www.mhvic.org.au/",'_system', 'location=yes');
  }

  function openBrowser8(){
    window.open("https://www.relationships.org.au/",'_system', 'location=yes');
  }

  function openBrowser9(){
    window.open("https://www.catholiccare.dow.org.au/",'_system', 'location=yes');
  }

  function openBrowser10(){
    window.open("https://www.anglicare.asn.au/find-a-provider",'_system', 'location=yes');
  }

  function openBrowser11(){
    window.open("https://www.missionaustralia.com.au",'_system', 'location=yes');
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton fill="clear" color="black" routerLink="/tab1" onClick={(e) => {
              e.preventDefault();
              history.push('/tab1')}}>Got it
            </IonButton>
          </IonButtons>
          <IonTitle>Support Centre</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Support Centre</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonList>
            <IonItem onClick={openBrowser12}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>National help lines and websites </h2>
                <h3>Beyond Blue: 1300-22-46-36 </h3>
                <a>www.beyondblue.org.au/get-support/national-help-lines-and-websites</a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={() => openBrowser("https://mesha.org.au/programs-and-resources")}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>MESHA </h2>
                <a>mesha.org.au/programs-and-resources </a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={() => openBrowser("https://fortemaustralia.org.au/")}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>FORTEM </h2>
                <a>fortemaustralia.org.au </a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={() => openBrowser("https://www.openarms.gov.au/")}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>Open Arms Veterans and Families Counselling </h2>
                <h3>Phone: 1800 011 046</h3>
                <a>www.openarms.gov.au </a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={openBrowser1}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>NSW </h2>
                <a>www.health.nsw.gov.au/mentalhealth/services/Pages/support-contact-list.aspx </a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={openBrowser2}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>SA </h2>
                <h3>Mental Health Triage Service: 13 14 65</h3>
                <a>www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/services/mental+health+and+drug+and+alcohol+services/mental+health+services/mental+health+services</a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={openBrowser3}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>QLD </h2>
                <h3>Within Australia: 13 QGOV (13 74 68)</h3>
                <a>www.qld.gov.au/health/mental-health</a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={openBrowser4}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>NT </h2>
                <h3>Mental Health Line: 1800 682 288</h3>
                <a>nt.gov.au/wellbeing/mental-health </a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={openBrowser5}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>WA </h2>
                <h3>1800 552 002 (Country/Rurallink)</h3>
                <a>www.mhc.wa.gov.au/getting-help/ </a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={openBrowser6}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>ACT </h2>
                <h3>Phone: 1800 629 354</h3>
                <a>www.health.act.gov.au/services-and-programs/mental-health/getting-help </a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={openBrowser7}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>VICTORIA </h2>
                <h3>HeadtoHelp: 1800 595 212</h3>
                <a>www.mhvic.org.au/getting-help </a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={openBrowser8}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>Relationships Australia  </h2>
                <h3>Relationships Australia: 1300 364 277</h3>
                <a>www.relationships.org.au/ </a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={openBrowser9}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>CatholicCare  </h2>
                <h3> Call us: 02 4227 1122</h3>
                <a>www.catholiccare.dow.org.au/ </a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={openBrowser10}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>Anglicare  </h2>
                <h3>Mobile: 0400 255 207</h3>
                <a>www.anglicare.asn.au/find-a-provider </a>
              </IonLabel>
            </IonItem>

            <IonItem onClick={openBrowser11}>
              <IonIcon icon={callOutline} />
              <IonCol size='0.5'></IonCol>
              <IonLabel>
                <h2>Mission Australia  </h2>
                <h3>Lifeline: 13 11 14 </h3>
                <a> www.missionaustralia.com.au </a>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Support;
