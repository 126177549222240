import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonGrid, IonRow, IonCol } from '@ionic/react';

const Knowledge: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
   
  return (
     <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Getting treatment for PTSD</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonGrid>
          <IonRow>
            <IonCol>
              <h5>Getting treatment for PTSD</h5>
              <p>There are three broad categories of treatment for PTSD:</p>
              <li>psychological treatments (talking therapies)</li>
              <li>physical treatments (medications)</li>
              <li>exercise, mindfulness and self-help.</li>
              <li>Often, a combination of treatments works best.</li>
              <h5>&nbsp;</h5>
              <h5>Find the best treatment for you</h5>
              <p>Everybody has a different experience. Your symptoms, any co-conditions (like anxiety or depression), and your personal preferences will influence which treatments are best for you. Talk to your GP or mental health professional about the best treatment for you.</p>
              <p> Evidence shows that exercise and mindfulness are very useful for PTSD. They can be used together with physical and psychological treatments.
                  You can get better. Many people who have had PTSD have been able to seek help, return to work, and live active, fulfilling lives.
              </p>
              <p> Sometimes, a team will be involved in your care. It’s still important that one professional coordinates and has overall responsibility for your treatment.</p>
              <p> https://www.blackdoginstitute.org.au/resources-support/post-traumatic-stress-disorder/treatment/</p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Knowledge;
