import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons,IonBackButton, IonGrid, IonRow, IonCol } from '@ionic/react';
 

const Knowledge: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
   
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Grounding Techniques</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h5>Grounding Techniques</h5>
              <p>Grounding techniques, also known as grounding exercises or grounding skills, can distract, reframe, or otherwise soothe distressing feelings and help to calm the body and mind from anxiety triggers. They shift your focus from past / negative perceptions to future thinking into the present moment.</p>
              <p>The following are grounding techniques that use the mind to reduce distress:</p>
              <h6>1. Mindfulness</h6>
              <p>Mindfulness is one of the most powerful grounding techniques, and embracing a more mindful approach to everyday life can benefit you in numerous ways. Mindfulness is an encompassing term that refers to being present and aware of your current thoughts, feelings, and bodily sensations. At the same time, it also means trying to accept your current reality without judging or trying to change it.</p>
              <h6>2. Meditation</h6>
              <p>Meditation refers to the intentional practice of staying present with your mind and body. Meditation can include activities like breath work, intentional walks, or progressive muscle relaxation. Research shows that practicing just 10 minutes of meditation a day can reduce anxiety and sharpen focus.</p>
              <h6>3. Use Your Five Senses</h6>
              <p>Grounding yourself with the five-sense exercise can help you when you feel overwhelmed or hyperactivated. It’s a distraction technique that supports present-moment thinking. Simply focus on five things you see, four things you feel, three things you hear, two things you smell, and one thing you can taste.</p>
              <h6>4. Safe Place</h6>
              <p>The “safe place” exercise is a type of guided imagery that promotes calmness and emotional security. When you feel heightened, close your eyes, and imagine a safe, comfortable place. It can be real or imagined. Try to embrace all the five senses while in this place. Spend as much time there as you need.</p>
              <h6>5. Try Breathwork</h6>
              <p>Breathwork is a conscious exercise where you control how you breathe. While you can do this technique on your own, it’s often helpful to learn it in a structured program. Breathwork allows you to deactivate your sympathetic nervous system while also activating your parasympathetic nervous system. Try lying on the floor with a book on top of your stomach, breathe in till it rises, do this 4 times, breathe in for 4 seconds, hold for 4 seconds and breathe out for 4 seconds</p>
              <p>&nbsp;</p>
              <p>References:</p>
              <p>What Is Mindfulness? (2022). Greater Good Science Center at the University of California, Berkeley.https://greatergood.berkeley.edu/topic/mindfulness/definition.</p>
              <p>The effect of grounding the human body on mood (2015, April). National Library of Medicine.Retrieved from: https://pubmed.ncbi.nlm.nih.gov/25748085/.</p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Knowledge;
