import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { calendarOutline, homeOutline, informationCircleOutline } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/Login';
import Register from './pages/Register';
import successfulRe from './pages/SuccessfulRe';
import Record from './pages/Record';
import Record02 from './pages/Record02';
import Record03 from './pages/Record03';
import Record04 from './pages/Record04';
import Record05 from './pages/Record05';
import Record06 from './pages/Record06';
import Record07 from './pages/Record07';
import Record08 from './pages/Record08';
import EditInfo from './pages/EditInfo';
import FeedbackPage from './pages/FeedbackPage';
import Support from './pages/Support';
import Manage from './pages/Manage';
import Knowledge from './pages/Knowledge';
import Knowledge01 from './pages/Knowledge01';
import Knowledge02 from './pages/Knowledge02';
import Knowledge03 from './pages/Knowledge03';
import Knowledge04 from './pages/Knowledge04';
import Knowledge05 from './pages/Knowledge05';
import demoGreaph from './pages/infoDemograph';
import personalInfo from './pages/infoPersonal';
import event from './pages/Event';
import emotiononly from './pages/Emotiononly';
import Cal from './pages/Calendar';
import {TheProvider}from"./some-content";

const App: React.FC = () => {
  return (
    <IonApp>
      <TheProvider>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/successfulRe" component={successfulRe} />
            <Route
              render={() => (
                <IonTabs>
                  <IonRouterOutlet>
                    <Route path="/tab1" render={props => <Tab1 {...props} />} exact={true}/>
                    <Route path="/record" component={Record} />
                    <Route path="/record02" component={Record02} />
                    <Route path="/record03" component={Record03} />
                    <Route path="/record04" component={Record04} />
                    <Route path="/record05" component={Record05} />
                    <Route path="/record06" component={Record06} />
                    <Route path="/record07" component={Record07} />
                    <Route path="/record08" component={Record08} />
                    <Route path="/feedback" component={FeedbackPage} />
                    <Route path="/support" component={Support} />
                    <Route path="/manage" component={Manage} />
                    <Route path="/knowledge" component={Knowledge} />
                    <Route path="/knowledge01" component={Knowledge01} />
                    <Route path="/knowledge02" component={Knowledge02} />
                    <Route path="/knowledge03" component={Knowledge03} />
                    <Route path="/knowledge04" component={Knowledge04} />
                    <Route path="/knowledge05" component={Knowledge05} />
                    <Route path="/infoDemograph" component={demoGreaph} />
                    <Route path="/infoPersonal" component={personalInfo} />
                    <Route path="/event" component={event} />
                    <Route path="/emotiononly" component={emotiononly} />

                    <Route path="/tab2" render={props => <Tab2 {...props} />} exact={true}/>
                    <Route path="/cal" component={Cal} />
                    
                    <Route path="/tab3" render={props => <Tab3 {...props} />} exact={true}/>
                    <Route path="/editInfo" component={EditInfo} />
                  </IonRouterOutlet>

                  <IonTabBar slot="bottom" >
                    <IonTabButton tab="tab1" href="/tab1">
                      <IonIcon icon={homeOutline} />
                      <IonLabel>Home</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="tab2" href="/tab2">
                      <IonIcon icon={calendarOutline} />
                      <IonLabel>Calender</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="tab3" href="/tab3">
                      <IonIcon icon={informationCircleOutline} />
                      <IonLabel>Personal Info</IonLabel>
                    </IonTabButton>
                  </IonTabBar>
                </IonTabs>
              )}
            />
            <Route
              path="/"
              render={() => <Redirect to="/login" />}
              exact={true}
            />
          </IonRouterOutlet>
        </IonReactRouter>
      </TheProvider>
    </IonApp>
  );
};

export default App;