import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton,IonButtons,IonBackButton, IonIcon,  IonGrid, IonRow, IonCol } from '@ionic/react';
import { arrowForwardCircleOutline } from 'ionicons/icons';
import AppContext from "../some-content";

const Event: React.FC<RouteComponentProps> = ({history}) => {

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  var userId = sharedValue.value;
  var chooseNode;
  var time;
  var location;
  var keyword;
  var incident;
  var detail;
  var positive;
  var positive_2;
  var positive_3;
  var tips;

  console.log(userId);

  if(history.location.state != undefined){
    const event = history.location.state as Array<Array<String>>;
    if(event[0] != undefined && event[0].length > 6){
      console.log(event[0]);

      time = event[0][3];
      location = event[0][4];
      incident = event[0][5];
      keyword = event[0][6];
      detail = event[0][7];
      positive = event[0][8];
      positive_2 = event[0][9];
      positive_3 = event[0][10];
      tips = event[0][12];
    }
    
    let titles:String[] = [];
    if(event[1] != undefined){
      for (var i = 1; i < event[1].length; i ++){
        titles.unshift(event[1][i]);
        console.log(event[1][i]);      
      }
      
      chooseNode = titles.map(function(content:String){
        return (
          <IonButton size="small" color="heal">{content}</IonButton>
        )
      });
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton fill="clear" routerLink="/tab1" onClick={(e) => {
              e.preventDefault();
              history.push('/tab1')}}>Got it
            </IonButton>
          </IonButtons>
          <IonTitle>Conversation</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image-theme">  
        <IonGrid>
          <IonRow className="conversationTitle">
            <b>&nbsp; I felt:</b>
          </IonRow>
          <IonRow>
            <IonCol>{chooseNode}</IonCol>
          </IonRow>
          <IonRow className="conversationTitle">
            <b>&nbsp; Event recorded:</b>
          </IonRow>

          <IonRow className="white">&nbsp; &nbsp;<IonButton size="small" color="yellow">Keyword:</IonButton>&nbsp; &nbsp; &nbsp; &nbsp;{keyword} </IonRow>
          <IonRow className="white">&nbsp; &nbsp;<IonButton size="small" color="healing">It happened:</IonButton>&nbsp; &nbsp;{time}</IonRow>
          <IonRow className="white">&nbsp; &nbsp;<IonButton size="small" color="pink">I was at:</IonButton>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{location}</IonRow>
          <IonRow className="white">&nbsp; &nbsp;<IonButton size="small" color="purple">Incident:</IonButton>&nbsp; &nbsp; &nbsp; &nbsp; {incident} </IonRow>
          <IonRow>&nbsp; &nbsp;<IonButton size="small" color="heal">My thoughts:</IonButton></IonRow>
          <IonRow className="white">&nbsp; &nbsp;<IonCol>{detail}</IonCol></IonRow>
          <IonRow>&nbsp; &nbsp;<IonButton size="small" color="pink">Positives in my life:</IonButton></IonRow>
          <IonRow className="white">&nbsp; &nbsp;<IonCol>{positive}; {positive_2}; {positive_3}.</IonCol></IonRow>
          <IonRow>&nbsp; &nbsp;<IonButton size="small" color="yellow">Self-care suggestions:</IonButton></IonRow>
          <IonRow className="white">&nbsp; &nbsp;<IonCol>{tips}</IonCol></IonRow>
          <IonRow> <h1></h1> </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton fill="solid" color = 'texttwo' href="/support" onClick={(e) => {
              e.preventDefault();history.push('/support')
              }}> You are not alone&nbsp;&nbsp;
              <IonIcon icon={arrowForwardCircleOutline} />
            </IonButton>
          </IonRow>
          <IonRow> <h1></h1> </IonRow>
          <IonRow> <h1></h1> </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default Event;
