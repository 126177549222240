import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonInput,IonToast,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonChip, IonButton,IonButtons,IonBackButton, IonCheckbox, IonLabel, IonGrid, IonRow } from '@ionic/react';
import './frontend.css';
import AppContext from "../some-content";
import axios from 'axios' ;
import { ownServer } from '../ownServer';

const Record: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  const [ emotion_input, setEmotionInput] = React.useState<string>("");
  
  var userId = sharedValue.value;
  var routerFinalLink;
  
  console.log(userId);

  const [showToast1, setShowToast1] = useState(false);
  const [sleeplesschecked, setSLChecked] = useState(false);
  const [peacefulchecked, setPFChecked] = useState(false);
  const [anxiouschecked, setAXChecked] = useState(false);
  const [stressedchecked, setSSChecked] = useState(false);
  const [alcoholchecked, setAUChecked] = useState(false);
  const [angrychecked, setAGChecked] = useState(false);
  const [noenergychecked, setNEChecked] = useState(false);
  const [emotionalchecked, setEMChecked] = useState(false);
  const [sadchecked, setSADChecked] = useState(false);
  const [sweatingchecked, setSWChecked] = useState(false);
  const [drugchecked, setDUChecked] = useState(false);
  const [controlledchecked, setCTChecked] = useState(false);
  const [lostchecked, setLSChecked] = useState(false);
  const [abandonedchecked, setADChecked] = useState(false);
  const [inadequacychecked, setIQChecked] = useState(false);
  const [failexpectationchecked, setFEChecked] = useState(false);
  const [breathingchecked, setBDChecked] = useState(false);
  const [sociallychecked, setWSChecked] = useState(false);
  const [burdenchecked, setBEChecked] = useState(false);
  const [numbnesschecked, setNBChecked] = useState(false);
  const [hyperchecked, setHAChecked] = useState(false);
  const [recurringchecked, setRVChecked] = useState(false);
  const [reactedchecked, setRPChecked] = useState(false);
  const [harmedchecked, setPHChecked] = useState(false);
  const [concentratechecked, setCCChecked] = useState(false);
  const [strugglingchecked, setSDChecked] = useState(false);

  function handleSaveClick(){
    let url = ownServer.prodIp + "/recordemotion";
    if(sleeplesschecked != false || peacefulchecked != false || anxiouschecked != false || stressedchecked != false || alcoholchecked != false || angrychecked != false || noenergychecked != false || emotionalchecked != false || sadchecked != false || sweatingchecked != false || 
      drugchecked != false || controlledchecked != false || lostchecked != false || abandonedchecked != false || inadequacychecked != false || failexpectationchecked != false || breathingchecked != false || sociallychecked != false || burdenchecked != false || numbnesschecked != false
      || hyperchecked != false || recurringchecked != false || reactedchecked != false || harmedchecked != false || concentratechecked != false || strugglingchecked != false || (emotion_input != "" && emotion_input != null)){
      
        let dataPost = {
          userid: userId,
          record_time: new Date().toISOString(),
          sleeplesschecked:sleeplesschecked,
          peacefulchecked:peacefulchecked,
          anxiouschecked:anxiouschecked,
          stressedchecked:stressedchecked,
          alcoholchecked:alcoholchecked,
          angrychecked:angrychecked,
          noenergychecked:noenergychecked,
          emotionalchecked:emotionalchecked,
          sadchecked:sadchecked,
          sweatingchecked:sweatingchecked,
          drugchecked:drugchecked,
          controlledchecked:controlledchecked,
          lostchecked:lostchecked,
          abandonedchecked:abandonedchecked,
          inadequacychecked:inadequacychecked,
          failexpectationchecked:failexpectationchecked,
          breathingchecked:breathingchecked,
          sociallychecked:sociallychecked,
          burdenchecked:burdenchecked,
          numbnesschecked:numbnesschecked,
          hyperchecked:hyperchecked,
          recurringchecked:recurringchecked,
          reactedchecked:reactedchecked,
          harmedchecked:harmedchecked,
          concentratechecked:concentratechecked,
          strugglingchecked:strugglingchecked,
          emotionInput:emotion_input,
        };
        console.log(dataPost.record_time);
        axios.post(url,dataPost)
        .then(function (response) {         
          let data = response.data;
          if(data == "error" || data == "fail"){
          }
          routerFinalLink = './record02';
            history.push({
              pathname:'/record02',
              state:data,
            });
          })
        .catch(function (error) {
          console.log(error);
        });
      
    }else{
      setShowToast1(true)
    }

    setSLChecked(false);
    setPFChecked(false);
    setAXChecked(false);
    setSSChecked(false);
    setAUChecked(false);
    setAGChecked(false);
    setNEChecked(false);
    setEMChecked(false);
    setSADChecked(false);
    setSWChecked(false);
    setDUChecked(false);
    setCTChecked(false);
    setLSChecked(false);
    setADChecked(false);
    setIQChecked(false);
    setFEChecked(false);
    setBDChecked(false);
    setWSChecked(false);
    setBEChecked(false);
    setNBChecked(false);
    setHAChecked(false);
    setRVChecked(false);
    setRPChecked(false);
    setPHChecked(false);
    setCCChecked(false);
    setSDChecked(false);
    setEmotionInput("");
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton fill='clear' routerLink={routerFinalLink} onClick={handleSaveClick}>Next</IonButton>
          </IonButtons>
          <IonTitle>Record</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image">
        <IonGrid>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonChip outline={false} className="title" > <h5>What do you feel now?</h5> </IonChip>
          </IonRow>
          <h5>&nbsp;</h5>

          <IonChip outline={true}>
            <IonCheckbox checked={peacefulchecked} onIonChange={e => setPFChecked(e.detail.checked)} />
            <IonLabel>peaceful</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={sleeplesschecked} onIonChange={e => setSLChecked(e.detail.checked)} />
            <IonLabel>sleepless</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={anxiouschecked} onIonChange={e => setAXChecked(e.detail.checked)} />
            <IonLabel>anxious</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={stressedchecked} onIonChange={e => setSSChecked(e.detail.checked)} />
            <IonLabel>stressed</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={alcoholchecked} onIonChange={e => setAUChecked(e.detail.checked)} />
            <IonLabel>alcohol use</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={angrychecked} onIonChange={e => setAGChecked(e.detail.checked)} />
            <IonLabel>angry</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={noenergychecked} onIonChange={e => setNEChecked(e.detail.checked)} />
            <IonLabel>no energy</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={emotionalchecked} onIonChange={e => setEMChecked(e.detail.checked)} />
            <IonLabel>emotional</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={sadchecked} onIonChange={e => setSADChecked(e.detail.checked)} />
            <IonLabel>sad</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={sweatingchecked} onIonChange={e => setSWChecked(e.detail.checked)} />
            <IonLabel>sweating</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={drugchecked} onIonChange={e => setDUChecked(e.detail.checked)} />
            <IonLabel>drug use</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={controlledchecked} onIonChange={e => setCTChecked(e.detail.checked)} />
            <IonLabel>out of control</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={lostchecked} onIonChange={e => setLSChecked(e.detail.checked)} />
            <IonLabel>feel lost</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={abandonedchecked} onIonChange={e => setADChecked(e.detail.checked)} />
            <IonLabel>abandoned</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={inadequacychecked} onIonChange={e => setIQChecked(e.detail.checked)} />
            <IonLabel>feelings of inadequacy</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={failexpectationchecked} onIonChange={e => setFEChecked(e.detail.checked)} />
            <IonLabel>fail meet expectations</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={breathingchecked} onIonChange={e => setBDChecked(e.detail.checked)} />
            <IonLabel>breathing difficulties</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={sociallychecked} onIonChange={e => setWSChecked(e.detail.checked)} />
            <IonLabel>withdrawn socially</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={burdenchecked} onIonChange={e => setBEChecked(e.detail.checked)} />
            <IonLabel>feel burden</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={numbnesschecked} onIonChange={e => setNBChecked(e.detail.checked)} />
            <IonLabel>numbness</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={hyperchecked} onIonChange={e => setHAChecked(e.detail.checked)} />
            <IonLabel>hyper activity</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={recurringchecked} onIonChange={e => setRVChecked(e.detail.checked)} />
            <IonLabel>recurring vision</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={reactedchecked} onIonChange={e => setRPChecked(e.detail.checked)} />
            <IonLabel>reacted poorly</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={harmedchecked} onIonChange={e => setPHChecked(e.detail.checked)} />
            <IonLabel>physically harmed</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={concentratechecked} onIonChange={e => setCCChecked(e.detail.checked)} />
            <IonLabel>can't concentrate</IonLabel>
          </IonChip>

          <IonChip outline={true}>
            <IonCheckbox checked={strugglingchecked} onIonChange={e => setSDChecked(e.detail.checked)} />
            <IonLabel>struggling decisions</IonLabel>
          </IonChip>
          <h3>&nbsp;</h3>
          <IonRow className="ion-justify-content-center">
            <IonChip outline={true}>
              <IonInput placeholder="Write other feelings" type="text" color="white" maxlength={999} value={emotion_input} onIonChange={e => setEmotionInput(e.detail.value!)}></IonInput>
            </IonChip>
          </IonRow>
          <h3>&nbsp;</h3>

        </IonGrid>
        <IonToast 
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Please at least select one or enter your feelings"
          duration={1200}
        />
      </IonContent>
    </IonPage>
  );
};

export default Record;
