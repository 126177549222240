import { RouteComponentProps } from 'react-router-dom';
import { IonCard,IonImg,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonGrid, IonRow } from '@ionic/react';
import React, { useEffect } from 'react';
import AppContext from "../some-content";
import pic from '../img/re.jpg';

const SuccessfulRe: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  var userId = sharedValue.value;

  console.log(userId);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>You are all set!</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image-theme">
      
        <IonCard>
          <IonImg src = {pic}></IonImg>
        </IonCard>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonButton color="texttwo" routerLink="/tab3" onClick={(e) => {
              e.preventDefault();
              history.push('/tab3')}}>Got it</IonButton>
          </IonRow>

        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SuccessfulRe;