import { RouteComponentProps } from 'react-router-dom';
import { IonToast,IonContent,IonBackButton, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonInput, IonButtons,IonButton, IonLabel, IonGrid, IonRow, IonAvatar, IonImg } from '@ionic/react';
import React, { useState } from 'react';
import axios from 'axios' ;
import './frontend.css'
import AppContext from "../some-content";
import { ownServer } from '../ownServer';
import pic from '../img/brain.jpg';

const Register: React.FC<RouteComponentProps> = ({history}) => {
  const { setSharedValue } = React.useContext(AppContext);
  const [emailInputR, setEmailValueR] = React.useState<string>();
  const [passwordInputR, setPasswordValueR] = React.useState<string>();
  const [vCodeInput, setVCodeInput] = React.useState<string>();
  const [showToastNull, setShowToastNull] = useState(false);
  const [showToastEmail, setShowEmail] = useState(false);
  const [showToastPWLength, setShowLength] = useState(false);
  const [showVCodeReminder, setVCodeReminder] = useState(false);
  const [showExistEmail, setShowExistEmail] = useState(false);

  var min_password = 6;
  var max_password = 20;
  var routerFinalLink;

  function handleRegistration(){
    let url = ownServer.prodIp + "/register";
    if(emailInputR != null && passwordInputR != null && vCodeInput != null){
      if(emailInputR.indexOf("@") == -1){
        setShowEmail(true)
      }else if(passwordInputR.length < 6){
        setShowLength(true)
      }
      else{
        let dataPost = {email: emailInputR, password: passwordInputR, vCode: vCodeInput};
        axios.post(url,dataPost)
        .then(function (response) {
          let data = response.data;
          if(data == "fail" || data == "not valid" || data == "existingEmail"){
            routerFinalLink = './register';
            setVCodeReminder(data == "not valid");
            setShowExistEmail(data == "existingEmail");
          } else{
            routerFinalLink="./successfulRe";
            history.push('/successfulRe');
            setSharedValue({ value: data, changedBy: "Register" })
          };
          console.log(data);
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }else{
      setShowToastNull(true);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Register</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image-theme">
        <h1></h1>
        <h1>&nbsp;</h1>
        <form action="" method="post">
        <IonItem className="background-blue" lines="inset">
          <IonLabel><b>Verification Code</b></IonLabel>
          <IonInput placeholder="Check the email" type="text" required={true} value={vCodeInput} onIonChange={e => setVCodeInput(e.detail.value!)}></IonInput>
        </IonItem>
        <IonItem className="background-blue" lines="inset">
          <IonLabel><b>Email</b></IonLabel>
          <IonInput placeholder="User@example.com" type="text" maxlength={100} required={true} value={emailInputR} onIonChange={e => setEmailValueR(e.detail.value!)}></IonInput>
        </IonItem>
        <IonItem className="background-blue" lines="inset">
          <IonLabel><b>Password</b></IonLabel>
          <IonInput placeholder="At least 6 digits" type="password" required={true} minlength={min_password} maxlength={max_password} value={passwordInputR} onIonChange={e => setPasswordValueR(e.detail.value!)}></IonInput>
        </IonItem>

        <IonGrid>
          <IonRow><h1></h1></IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton expand="block" color="texttwo" routerLink={routerFinalLink} onClick={handleRegistration}>Register</IonButton>
          </IonRow>
        </IonGrid>
        </form>

        <IonRow>
            <h1>&nbsp;</h1>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton size='large' fill='clear' color='white'>
              The Mental Mentor
            </IonButton>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton size='small' fill='clear' color='white'>
              www.thementalmentorwhelenrose.com
            </IonButton>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonAvatar className="ion-justify-content-center">
              <IonImg src = {pic}></IonImg>
            </IonAvatar>
          </IonRow>
        
        <IonToast 
          isOpen={showToastNull}
          onDidDismiss={() => setShowToastNull(false)}
          message="Validation Code, Email or Password cannot be empty."
          duration={800}
        />
        <IonToast 
          isOpen={showToastEmail}
          onDidDismiss={() => setShowEmail(false)}
          message="Sorry, not an Email."
          duration={800}
        />
        <IonToast 
          isOpen={showToastPWLength}
          onDidDismiss={() => setShowEmail(false)}
          message="Sorry, at least 6 digits for Password."
          duration={800}
        />
        <IonToast 
          isOpen={showVCodeReminder}
          onDidDismiss={() => setVCodeReminder(false)}
          message="The code you provided is not valid. Please check the verification code in the email received when you purchased our App."
          duration={4000}
        />
        <IonToast 
          isOpen={showExistEmail}
          onDidDismiss={() => setShowExistEmail(false)}
          message="This email has already been registered. Please register another email or login."
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Register;