import { RouteComponentProps } from 'react-router-dom';
import { IonToast,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton,IonButtons,IonIcon, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonSelect, IonSelectOption } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import AppContext from "../some-content";
import axios from 'axios' ;
import { ownServer } from '../ownServer';

const InfoDemograph: React.FC<RouteComponentProps> = ({history}) => {

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  const [ genderD, setgenderD] = React.useState<string>();
  const [ age_group, setAgeGroup] = React.useState<string>();
  const [ occupation_group, setOccupationGroup] = React.useState<string>();
  const [showToast1, setShowToast1] = useState(false);
  const demo = history.location.state as Array<String>;

  let routerFinalLink;
  var userId = sharedValue.value;
  var gender, age_g, occupation_g;

  console.log(userId);

  if(demo != undefined){
    if(demo[1] != "Unknown" && demo[2] != "Unknown" && demo[3] != "Unknown"){
      gender = demo[1] as string;
      age_g = demo[2] as string;
      occupation_g = demo[3] as string;
    }else{
      gender="";
      age_g="";
      occupation_g="";
    }
  }

  function handleSaveClick(){
    let url = ownServer.prodIp + "/infodemograph";
    if(genderD != null && age_group != null && occupation_group != null){

      let dataPost = {userid: userId,genderDemo:genderD,age_group:age_group,occupation_group:occupation_group};
      axios.post(url,dataPost)
      .then(function (response) {          
        let data = response.data;
        if(data == "error" || data == "fail"){}
        routerFinalLink = './tab3';
        history.push({
          pathname:'/tab3',
          state:data
        });
      })
      .catch(function (error) {
        console.log(error);
      });
      
    }else{
      setShowToast1(true)
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/* <IonButtons slot="start">
            <IonBackButton />
          </IonButtons> */}
          <IonButtons slot="start">
            <IonButton routerLink="/tab3" onClick={(e) => {
              e.preventDefault();
              history.push('/tab3')}}><IonIcon icon={chevronBackOutline}></IonIcon>Edit later
            </IonButton>
          </IonButtons>
          <IonTitle>Edit Info</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Edit Info</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow><h1></h1> </IonRow>
          <IonRow>
            <IonCol>
                <h1>Demographic Info</h1>
            </IonCol>
          </IonRow>
          <IonRow><h1></h1> </IonRow>
        </IonGrid>

        <IonToast 
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Some selection still empty"
          duration={800}
        />

        <IonList>
          <IonItem>
            <IonLabel >Gender</IonLabel>
            <IonSelect placeholder={gender} ok-text="Okay" cancel-text="Cancel" onIonChange={e => setgenderD(e.detail.value)}>
              <IonSelectOption value="Male">Male</IonSelectOption>
              <IonSelectOption value="Female">Female</IonSelectOption>
              <IonSelectOption value="Non-Conforming">Non-Conforming</IonSelectOption>
              <IonSelectOption value="NotListed">Not Listed</IonSelectOption>
              <IonSelectOption value="NoSay">Prefer Not to Say</IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonLabel >Age Group</IonLabel>
            <IonSelect placeholder={age_g} ok-text="Okay" cancel-text="Cancel" onIonChange={e => setAgeGroup(e.detail.value)}>
              <IonSelectOption value="16-25">16-25</IonSelectOption>
              <IonSelectOption value="26-35">26-35</IonSelectOption>
              <IonSelectOption value="36-45">36-45</IonSelectOption>
              <IonSelectOption value="46-55">46-55</IonSelectOption>
              <IonSelectOption value="56-65">56-65</IonSelectOption>
              <IonSelectOption value="66+">66+</IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonLabel >Occupation</IonLabel>
            <IonSelect placeholder={occupation_g} ok-text="Okay" cancel-text="Cancel" onIonChange={e => setOccupationGroup(e.detail.value)}>
              <IonSelectOption value="Police">Police</IonSelectOption>
              <IonSelectOption value="Ambulance">Ambulance</IonSelectOption>
              <IonSelectOption value="FireOfficer">Fire Officer</IonSelectOption>
              <IonSelectOption value="Medical/Hospital/Clinical">Medical</IonSelectOption>
              <IonSelectOption value="Army">Army</IonSelectOption>
              <IonSelectOption value="Navy">Navy</IonSelectOption>
              <IonSelectOption value="Airforce">Airforce</IonSelectOption>
              <IonSelectOption value="Agriculture">Agriculture</IonSelectOption>
              <IonSelectOption value="Construction">Construction FIFO</IonSelectOption>
              <IonSelectOption value="Teaching">Teaching</IonSelectOption>
              <IonSelectOption value="Legal">Legal Services</IonSelectOption>
              <IonSelectOption value="Others">Others</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList>

        <IonRow><h1></h1> </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonButton color="texttwo" fill="solid" size="large" routerLink={routerFinalLink} onClick={handleSaveClick}>Save
          </IonButton>
        </IonRow>

      </IonContent>
    </IonPage>
  );
};

export default InfoDemograph;
