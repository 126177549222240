import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonToast, IonContent, IonInput,IonHeader, IonPage, IonTitle, IonToolbar, IonChip, IonButton,IonButtons, IonGrid, IonRow, IonCol } from '@ionic/react';
import './frontend.css';
import AppContext from "../some-content";
import axios from 'axios' ;
import { ownServer } from '../ownServer';

const Record06: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const [ incident_selfdefine, setIncident] = React.useState<string>();
  const { sharedValue } = React.useContext(AppContext);
  const [showToast1, setShowToast1] = useState(false);

  var userId = sharedValue.value;
  var event = history.location.state;
  var incident;
  let routerFinalLink;

  console.log(userId);
  console.log(event);

  function handleSaveClickSelf(){
    let url = ownServer.prodIp + "/recordincident";
    if(incident_selfdefine != "" && incident_selfdefine != null){    
      let dataPost = {
        userid: userId,
        eventid:event,
        incidentInput:incident_selfdefine,
      };
      axios.post(url,dataPost)
      .then(function (response) {          
        let data = response.data;
        if(data == "error" || data == "fail"){
        }
          routerFinalLink = './record07';
          history.push({
            pathname:'/record07',
            state:event,
          });
        })
      .catch(function (error) {
        console.log(error);
      });
    }else{
      setShowToast1(true)
    }
    setIncident("");
  }

  function handleSaveClick(incidentPara:any){
    let url = ownServer.prodIp + "/recordincident";
    let dataPost = {
      userid: userId,
      eventid:event,
      incidentInput:incidentPara,
    };
    axios.post(url,dataPost)
    .then(function (response) {          
      let data = response.data;
      if(data == "error" || data == "fail"){
      }
        routerFinalLink = './record07';
        history.push({
          pathname:'/record07',
          state:event,
        });
      })
    .catch(function (error) {
      console.log(error);
    });
  }

  function handleLifeClick(){
    incident = "Life threatened";
    handleSaveClick(incident);
  }

  function handleFFAClick(){
    incident = "Fire, family and animals at risk"
    handleSaveClick(incident);
  }

  function handleFFClick(){
    incident = "Bullying";
    handleSaveClick(incident);
  }
  
  function handleMotorClick(){
    incident = "Motor vehicle accident – fatal";
    handleSaveClick(incident);
  }

  function handleViolenceClick(){
    incident = "Domestic violence";
    handleSaveClick(incident);
  }

  function handleCombatClick(){
    incident = "Being deployed on duty"
    handleSaveClick(incident);
  }

  function handleChildClick(){
    incident = "Child related";
    handleSaveClick(incident);
  }
  
  function handleColleagueClick(){
    incident = "Colleague injured or killed";
    handleSaveClick(incident);
  }

  function handleKillClick(){
    incident = "Killed someone in line of duty"
    handleSaveClick(incident);
  }

  function handleStabbingClick(){
    incident = "Physical violence";
    handleSaveClick(incident);
  }
  
  function handleAssaultedClick(){
    incident = "Assaulted";
    handleSaveClick(incident);
  }

  function handleViClick(){
    incident = "Vicarious";
    handleSaveClick(incident);
  }

  function handleFSClick(){
    incident = "Feelings of separation";
    handleSaveClick(incident);
  }

  function handleIsoClick(){
    incident = "Isolation";
    handleSaveClick(incident);
  }

  function handleAbusedClick(){
    incident = "Verbally abused/coercive control";
    handleSaveClick(incident);
  }

  function handleWitnessClick(){
    incident = "Witnessed an incident";
    handleSaveClick(incident);
  }

  function handleNDClick(){
    incident = "Natural disaster - fire/flood/drought";
    handleSaveClick(incident);
  }

  function handleFIFOClick(){
    incident = "FIFO";
    handleSaveClick(incident);
  }

  function handleMarriageClick(){
    incident = "Marriage breakbown";
    handleSaveClick(incident);
  }

  function handleFamilyClick(){
    incident = "Loss of family member";
    handleSaveClick(incident);
  }

  function handleIllnessClick(){
    incident = "Incurable illness/recovering from illness";
    handleSaveClick(incident);
  }

  function handleBadDayClick(){
    incident = "Just a bad day";
    handleSaveClick(incident);
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {/* <IonBackButton /> */}
          </IonButtons>
          <IonButtons slot="end">
            <IonButton fill="clear" color="black" routerLink={routerFinalLink} onClick={handleSaveClickSelf}>Next
            </IonButton>
          </IonButtons>
          <IonTitle>Record 4/6</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image">

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonRow className="ion-justify-content-center">
                <IonChip outline={false} className="title"> <h5> What was the incident? </h5></IonChip>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol>
              <IonButton color="pink" size="small" routerLink={routerFinalLink} onClick={handleBadDayClick}>Just a bad day</IonButton>
              <IonButton color="yellow" size="small" routerLink={routerFinalLink} onClick={handleLifeClick}>Life threatened</IonButton>
              <IonButton color="heal" size="small" routerLink={routerFinalLink} onClick={handleFFAClick}>family and animals at risk</IonButton>
              <IonButton color="healing" size="small" routerLink={routerFinalLink} onClick={handleFFClick}>Bullying</IonButton>
              <IonButton color="pink" size="small" routerLink={routerFinalLink} onClick={handleMotorClick}>Motor vehicle accident</IonButton>
              <IonButton color="purple" size="small" routerLink={routerFinalLink} onClick={handleViolenceClick}>Domestic violence</IonButton>
              <IonButton color="yellow" size="small" routerLink={routerFinalLink} onClick={handleCombatClick}>Being deployed on duty</IonButton>
              <IonButton color="heal" size="small" routerLink={routerFinalLink} onClick={handleChildClick}>Child related</IonButton>
              <IonButton color="heal" size="small" routerLink={routerFinalLink} onClick={handleAssaultedClick}>Assaulted</IonButton>
              <IonButton color="healing" size="small" routerLink={routerFinalLink} onClick={handleColleagueClick}>Colleague injured or killed</IonButton>
              <IonButton color="pink" size="small" routerLink={routerFinalLink} onClick={handleKillClick}>Killed x in line of duty</IonButton>
              <IonButton color="light" size="small" routerLink={routerFinalLink} onClick={handleStabbingClick}>Physical violence</IonButton>
              <IonButton color="healing" size="small" routerLink={routerFinalLink} onClick={handleViClick}>Vicarious-Read/in 3rd person</IonButton>
              <IonButton color="pink" size="small" routerLink={routerFinalLink} onClick={handleFSClick}>Feelings of separation</IonButton>
              <IonButton color="purple" size="small" routerLink={routerFinalLink} onClick={handleIsoClick}>Isolation</IonButton>
              <IonButton color="yellow" size="small" routerLink={routerFinalLink} onClick={handleAbusedClick}>Verbally abused</IonButton>
              <IonButton color="heal" size="small" routerLink={routerFinalLink} onClick={handleWitnessClick}>Witnessed an incident</IonButton>
              <IonButton color="pink" size="small" routerLink={routerFinalLink} onClick={handleFIFOClick}>FIFO</IonButton>
              <IonButton color="healing" size="small" routerLink={routerFinalLink} onClick={handleNDClick}>Natural disaster</IonButton>
              <IonButton color="purple" size="small" routerLink={routerFinalLink} onClick={handleMarriageClick}>Marriage breakbown</IonButton>
              <IonButton color="yellow" size="small" routerLink={routerFinalLink} onClick={handleFamilyClick}>Loss of family member</IonButton>
              <IonButton color="heal" size="small" routerLink={routerFinalLink} onClick={handleIllnessClick}>Incurable illness</IonButton> 
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonChip>
              <IonInput maxlength={999} placeholder="The incident type" type="text" value={incident_selfdefine} onIonChange={e => setIncident(e.detail.value!)}></IonInput>
            </IonChip>
          </IonRow> 
        </IonGrid>
        <IonToast 
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Please choose or type one"
          duration={800}
        />

      </IonContent>
    </IonPage>
  );
};

export default Record06;
