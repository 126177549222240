import { RouteComponentProps } from 'react-router-dom';
import { IonToast,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton,IonButtons, IonIcon, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonInput } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import AppContext from "../some-content";
import axios from 'axios' ;
import { ownServer } from '../ownServer';

const InfoPersonal: React.FC<RouteComponentProps> = ({history}) => {

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  const [ name, setName] = React.useState<string>();
  const [ age, setAge] = React.useState<string>();
  const [ occupation, setOccupation] = React.useState<string>();
  const [ country, setCountry] = React.useState<string>();
  const [ state, setState] = React.useState<string>();
  const [showToast1, setShowToast1] = useState(false);
  const demo = history.location.state as Array<String>;
  var name_p, age_p, occupation_p,country_p,state_p;
  var userId = sharedValue.value;
  let routerFinalLink;

  console.log(userId);

  if(demo != undefined){
    if(demo[4] != "Unknown" && demo[5] != "Unknown" && demo[6] != "Unknown" && demo[7] != "Unknown" && demo[8] != "Unknown"){
      name_p = demo[4] as string;
      age_p = demo[5] as string;
      occupation_p = demo[6] as string;
      country_p = demo[7] as string;
      state_p = demo[8] as string;
    }else{
      name_p = "Please enter your name here"
      age_p = "Please enter your age here"
      occupation_p = "Please enter your occupation"
      country_p = "Country"
      state_p = "State"
    }
  }

  function handleSaveClick(){
    let url = ownServer.prodIp + "/infopersonal";
    if(name != null && age != null && occupation != null && country != null && state != null){
        let dataPost = {userid: userId,name:name,age:age,occupation:occupation,country:country,state:state};
        axios.post(url,dataPost)
        .then(function (response) {
          let data = response.data;
          if(data == "correct"){
            routerFinalLink = './tab3';
            history.push({
              pathname:'/tab3',
              state:data
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });   
    }else{
      setShowToast1(true)
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/* <IonButtons slot="start">
            <IonBackButton />
          </IonButtons> */}
          <IonButtons slot="start">
            <IonButton routerLink="/tab3" onClick={(e) => {
              e.preventDefault();
              history.push('/tab3')}}><IonIcon icon={chevronBackOutline}></IonIcon>Edit later
            </IonButton>
          </IonButtons>
          <IonTitle>Personal Info</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Edit Info</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol>
                <h1>Personal Info</h1>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonToast 
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Some selection still empty"
          duration={800}
        />

        <IonList>
          <IonItem>
            <IonLabel >Name</IonLabel>
            <IonInput type="text" maxlength={99} placeholder={name_p} value={name} onIonChange={e => setName(e.detail.value!)}></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel >Age</IonLabel>
            <IonInput type="text" maxlength={20} placeholder={age_p} value={age} onIonChange={e => setAge(e.detail.value!)}></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel >Occupation</IonLabel>
            <IonInput type="text" maxlength={99} placeholder={occupation_p} value={occupation} onIonChange={e => setOccupation(e.detail.value!)}></IonInput>
          </IonItem>
          
          <IonItem>
            <IonLabel >Location</IonLabel>
            <IonInput type="text" maxlength={49} placeholder={country_p} value={country} onIonChange={e => setCountry(e.detail.value!)}></IonInput>
            <IonInput type="text" maxlength={50} placeholder={state_p} value={state} onIonChange={e => setState(e.detail.value!)}></IonInput>
          </IonItem>
        </IonList>

        <IonRow><h1></h1> </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonButton color="texttwo" fill="solid" size="large" routerLink={routerFinalLink} onClick={handleSaveClick}>Save
          </IonButton>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default InfoPersonal;
