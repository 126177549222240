import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonToast,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonTextarea, IonButton,IonButtons, IonLabel, IonGrid, IonRow, IonCol } from '@ionic/react';
import AppContext from "../some-content";
import axios from 'axios' ;
import { ownServer } from '../ownServer';

const Record07: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])
  
  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  const [text, setText] = useState<string>();
  const [showToast1, setShowToast1] = useState(false);
  
  var userId = sharedValue.value;
  var event = history.location.state;
  let routerFinalLink;

  console.log(userId);
  console.log(event);

  function handleSaveClick(){
    let url = ownServer.prodIp + "/recordeventdetail";
    
    if(text != "" && text != null){
      let dataPost = {text:text,userid: userId,eventid:event,};
      axios.post(url,dataPost)
      .then(function (response) {
        let data = response.data;
        if(data == "error" || data == "fail"){
        }
        else{
          routerFinalLink="./record08";
          history.push({
            pathname:'/record08',
            state:event
          });
        };
        console.log(data);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    else{
      setShowToast1(true)
    }
    setText("");
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {/* <IonBackButton /> */}
          </IonButtons>
          <IonButtons slot="end">
            <IonButton fill="clear" color="black" routerLink={routerFinalLink}  onClick={handleSaveClick}>Next
            </IonButton>
          </IonButtons>
          <IonTitle>Record 5/6</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image-theme">
        <IonGrid>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <h4 className='white'>Tell your story</h4>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow> <IonCol>
            <IonLabel position="floating" color="white">Description</IonLabel>
            <IonTextarea placeholder="click here and type as long or short as you want" maxlength={9999} color="white" auto-grow = "true" value={text} onIonChange={e => setText(e.detail.value!)}></IonTextarea>
          </IonCol> </IonRow>          
        </IonGrid>

        <IonToast 
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Cannot be empty"
          duration={800}
        />

      </IonContent>
    </IonPage>
  );
};

export default Record07;
