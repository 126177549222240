import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonChip, IonButton,IonButtons, IonLabel, IonGrid, IonRow, IonCol } from '@ionic/react';
import AppContext from "../some-content";

const Record02: React.FC<RouteComponentProps> = ({history}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  var userId = sharedValue.value;
  var event = history.location.state;

  console.log(userId);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {/* <IonBackButton /> */}
          </IonButtons>
          <IonTitle>Record</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background-image">

        <IonGrid>
          <IonRow>
            <h1>&nbsp;</h1>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonChip className="bigTitle">
                <h5>Hi, It's time to start the conversation!</h5>
            </IonChip>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonButton routerLink="/record03" color="heal" size="large" fill ="solid" onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname:'/record03',
                state:event
              })}}>Sure
            </IonButton>
          </IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
            <h1>&nbsp;</h1>
          </IonRow>
          <IonRow> <IonCol> <IonLabel color="heal">We recommend you find a quiet place that you can work through the recording free from distractions.</IonLabel> </IonCol></IonRow>
          <IonRow>
            <h1>&nbsp;</h1>
            <h1>&nbsp;</h1>
          </IonRow>
          
          <IonRow className="ion-justify-content-end">
            <IonButton routerLink="/tab1" color="heal" size="default" fill ="clear" onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname:'/tab1',
                state:userId
              })}}>Not today
            </IonButton>
          </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default Record02;
