import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonPage, IonButton, IonRow } from '@ionic/react';
import './frontend.css';
import axios from 'axios' ;
import AppContext from "../some-content";
import { ownServer } from '../ownServer';

const Tab2: React.FC<RouteComponentProps> = ({history}) => {

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const alertUser = (e: { preventDefault: () => void; returnValue: string; }) => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to reload this page? If you do you will be logged out.'
  }
  const { sharedValue } = React.useContext(AppContext);
  const userId = sharedValue.value;
  // const [showToast, setShowToast] = useState(true);

  let routerFinalLink;

  console.log(userId);

  function handleOpenCalClick(){
    let url = ownServer.prodIp + "/manage";
    let dataPost = {userid: userId,};
    axios.post(url,dataPost)
    .then(function (response) {
      let data = response.data;
      if(data == "error" || data == "fail"){
        console.log("error: can't access event list in /manage")
      }
      else{
        routerFinalLink="./cal";
        history.push({
          pathname:'/cal',
          state:data.Event
        });
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return (
    <IonPage>
      <IonContent class="background-image">
        <h1>&nbsp;</h1>
        <h1>&nbsp;</h1>
        <h1>&nbsp;</h1>
        <h1>&nbsp;</h1>
        <h1>&nbsp;</h1>
        
        <IonRow className="ion-justify-content-center">
            <IonButton color="bluetwo" fill='solid' size="large" routerLink={routerFinalLink} onClick={handleOpenCalClick}>
              Open My Calendar
            </IonButton>
        </IonRow>

        {/* <IonToast 
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Friendly Reminder: if you are an iPhone/iPad user, the events may not appear in the Calendar, however you can always access them in the Manage section on the Home tab. Sorry for the inconvenience. We're working with Apple to resolve this issue."
          duration={8000}
        /> */}
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
